<template>
  <div>
    <NavBar :title="pageTitle" @showNavigation="nav = !nav" />
    <v-main>
      <v-container fluid>
        <Tabs class="">
          <!-- <Tab label="Open Findings">
            <OpenFindings />
          </Tab> -->
          <Tab label="Findings Archive">
            <Archive />
          </Tab>
        </Tabs>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Archive from '@/components/shared/archive'
import NavBar from '@/components/UI/nav-bar'

export default {
  name: 'OBHome',
  components: {
    Archive,
    NavBar
  },
  mounted() {
    this.$emit('loaded')
  },
  setup() {
    const pageTitle = 'Advisory Committee / Observer Home'
    return {
      pageTitle
    }
  }
}
</script>
